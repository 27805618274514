.expert-team {
  background: var(--bg-lightgray);
}

.expert-team {
  background-position: bottom center;
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: #DEEEFC;
  background-image: url(../images/bg-light-contact.png);
}

.team-box {
  box-shadow: 7px 5px 30px rgba(72, 73, 121, .15);
  background: var(--blue);
  position: relative;
  transition: .4s;
  padding-top: 01px;
  padding: 50px 25px 25px;
  margin-top: 85px;
  border: 1px solid var(--blue);
}

.team-box p {
  margin-bottom: 20px;
}

.team-img {
  width: 12rem;
  height: 12rem;
  border-radius: 100%;
  border: 1px solid var(--blue);
  margin: -7rem auto 0;
  overflow: hidden;

  img {
    transition: 0.3s;
  }
}

.team-img img:hover {
  transform: scale(1.1);
}

.ceo-message {
  font-style: italic;
}

.expert-team .common-box {
  height: 100%;
  border-radius: 1rem;
}

@media (max-width: 767px) {
  .expert-team {

    .common-box {
      height: fit-content !important;
    }

    .row {
      margin-top: 9rem;
      /* gap: 1rem; */
    }
  }
}