.our-branch {
  background: var(--blue);
}

.our-branch h4,
.our-branch p,
.our-branch li {
  color: #fff !important;
}

.our-branch a {
  color: #076589;
  font-weight: bold;
}

.our-branch {
  background-color: #1d3346;
  color: #fff;
  /* White text */
  padding: 30px 0;
}

.our-branch-info img {
  width: 10rem;
}

.our-branch h4 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

.our-branch ul {
  list-style: none;
  padding: 0;
}

.our-branch ul li {
  margin-bottom: 5px;
}

.our-branch p {
  margin-bottom: 5px;
}

.our-branch iframe {
  border: none;
  width: 100%;
  height: 200px;
}

@media only screen and (max-width: 767px) {
  .our-branch {
    text-align: center;

    .our-branch-info {
      gap: 1rem;

      .logo-2 {
        display: flex;
        justify-content: center;
      }
    }
  }
}