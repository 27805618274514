.client-marquee {
  /* background: var(--bg-lightgray); */
  background: #a2d2fb;
}

.feedback-box {
  width: 300px;
  display: inline-block;
  vertical-align: top;
}

.client-img img {
  width: fit-content;
  min-height: 50px;
  max-height: 50px;
}

.title-box h2 {
  margin-bottom: 20px;
}

.title-box p {
  margin-bottom: 30px;
}

.feedback-box {
  padding: 25px;
  margin-top: 25px;
  transition: 0.5s;
}

.client-info {
  display: flex;
  flex-direction: column;
}

.client-info .client-img {
  width: fit-content;
}

.feedback-box p {
  line-height: 1.5;
  margin-bottom: 0;
  font-size: 15px;
  transition: 0.5s;
}

.client-info h5 {
  text-transform: capitalize;
  line-height: 1.3;
  transition: 0.5s;
  word-break: normal;
  height: 50px;
    display: flex;
    align-items: center;
}

.marquee {
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  white-space: nowrap;
}

.marquee-content {
  display: flex;
  animation: marquee 20s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.item {
  flex: 0 0 auto;
}

.feedback-box {
  width: fit-content;
}

.title-box h2 {
  margin-bottom: 20px;
}
