a,
.section-title h2 span,
.typed-cursor,
.media-icon i,
.featured-package .primary-btn,
.header .navbar-nav>li>a.active,
.header .navbar-nav>li>a:hover,
.header .navbar-nav>li>a:focus,
.how-works-item .icon-outer {
    color: #4285F4;
}

a:hover,
a:focus {
    color: #0075F8;
}

.gredient-overlay,
.bg-gray .featured-package,
.featured-package {
    background: #4285F4;
    background: -webkit-linear-gradient(to left, #4285F4, #0075F8);
    background: -webkit-linear-gradient(right, #4285F4, #0075F8);
    background: linear-gradient(to left, #4285F4, #0075F8);
}

.single-package,
.primary-btn,
.media:hover .media-icon,
.widget h3:before,
.dropdown-menu>.active>a,
.dropdown-menu>.active>a:hover,
.scrollup,
.header .nav li a:before,
.header .nav li a:after,
.how-works-item:hover .icon-outer,
.panel-heading,
.primary-bg {
    background: #4285F4;
    color: #fff;
}

.primary-btn:hover,
.sandy-pagination .pagination>li.active>a,
.header-nav .navbar-nav>li .sub-menu li>a:hover,
.sandy-pagenation a:hover,
.sandy-pagenation a.active {
    background: #0075F8;
}

.lines,
.lines:before,
.lines:after {
    border-top: 2px solid #4285F4;
}

@-webkit-keyframes color {
    100%,
    0% {
        stroke: #4285F4;
    }
    40% {
        stroke: #4285F4;
    }
    66% {
        stroke: #4285F4;
    }
    80%,
    90% {
        stroke: #4285F4;
    }
}

@keyframes color {
    100%,
    0% {
        stroke: #4285F4;
    }
    40% {
        stroke: #4285F4;
    }
    66% {
        stroke: #4285F4;
    }
    80%,
    90% {
        stroke: #4285F4;
    }
}

.media-icon,
.post-thumb,
.sandy-pagination .pagination>li.active>a,
.avatar,
.scrollup:hover,
.tagcloud a,
.how-works-item .icon-outer {
    border: 1px dashed #4285F4;
    border-radius: 5px;
    padding: 0.5rem;
}

.blog-footer {
    border-top: 1px dashed #4285F4;
}

.input-box:focus,
.textarea-box:focus {
    border: 1px solid #4285F4;
}

.about-boxes .media {
    border-color: #4285F4;
}

.primary-btn-2 {
    background: #222D39;
}

.primary-btn-2:hover {
    background: #677294;
}

.primary-btn-3 {
    background: #677294;
}

.primary-btn-3:hover {
    background: #222D39;
}