.about {

  .title-box {
    h2 {
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }

    img {
      width: 20rem;
    }
  }

  .common-box {
    height: 100%;
    border-radius: 1rem;
  }

  .common-box.media:hover {
    transform: scale(1.1);
    background: #deeefc;
    border: solid 0.1px #0087ff;
    z-index: 1;
  }
}

@media (max-width: 767px) {
  .welcome-box {

    .title-box {
      h2 {
        align-items: center !important;
        justify-content: center !important;
        flex-direction: column !important;
      }
    }

    .row {
      gap: 1rem;
    }
  }
}