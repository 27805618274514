.latest-news {
  background: var(--bg-lightgray);
}

.card-title {
  font-size: 20px;
  font-weight: 700;
  text-transform: capitalize;
  color: #000;
  margin: 5px 0 20px;
}

.card.card-body {
  border: none;
}