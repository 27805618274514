.marketing-section {
  background: var(--blue);
  /* background: url(../images/about-back.jpg); */
}

.marketing-section .media-left {

  width: 70px;
  background: var(--white);
  height: 70px;
  text-align: center;
  line-height: 70px;
  color: var(--blue);
  border: 1px solid var(--white);
  border-radius: 50%;
  font-size: 30px;
  transition: .4s;
  margin-right: 15px;
}

.market-box {
  margin-bottom: 35px;
}

.marketing-section .media {
  display: flex;
  align-items: flex-start;
}

.marketing-section .media-body {
  flex: 1 1;
}

.market-box:last-child {
  margin-bottom: 0;
}

.market-box .media-heading {
  font-size: 20px;
  font-weight: 500;
  color: var(--white);
  margin: 0 0 15px;
}

.market-box .media-body {
  font-size: 16px;
  color: var(--white);
  line-height: 1.5;
  margin: 0 0 0px;
}