footer {
  background: var(--gray-dark);
  padding: 25px 0;
}

footer p {
  color: var(--white);
  margin: 0;
}

.footer-menu {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
  align-items: center;
  justify-content: flex-end;
}

.footer-menu a {
  color: var(--white);
  background-color: transparent;
  border: none;
}

.footer-menu a:first-child:before {
  display: none;
}

.footer-menu a:hover {
  color: var(--blue);
}

.footer-menu a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 13px;
  background: #fff;
  width: 2px;
  height: 15px;
}